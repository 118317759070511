import "../index.css";
import React from "react";
import calculadoraslist from "../utils/calculadoraslist";

const Calculadoras = ({ setRoute }) => {
  return (
    <div className="botonera">
      {calculadoraslist.map((calculadoraslist, i) => (
        <div
          key={i}
          className="card"
          onClick={() => setRoute(`${calculadoraslist.click}`)}
          id={calculadoraslist.id}
        >
          <a
            target="_blank"
            rel="noreferrer"
            className="card-a"
            href={calculadoraslist.link}
          >
            <img src={calculadoraslist.img} className="card-img" alt={`imagen-${i}`} />
            {/* <GiStarsStack /> */}
            <p className="card-text">{calculadoraslist.texto}</p>
          </a>
        </div>
      ))}
    </div>
  );
};

export default Calculadoras;

import "../index.css";
import React from "react";
import juegoslist from "../utils/juegoslist";

const Juegos = ({ setRoute }) => {
  return (
    <div className="botonera">
      {juegoslist.map((juegoslist, i) => (
        <div
          key={i}
          className="card"
          onClick={() => setRoute(`${juegoslist.click}`)}
          id={juegoslist.id}
        >
          <a
            target="_blank"
            rel="noreferrer"
            className="card-a"
            href={juegoslist.link}
          >
            <img src={juegoslist.img} className="card-img" alt={`imagen-${i}`} />
            {/* <GiStarsStack /> */}
            <p className="card-text">{juegoslist.texto}</p>
          </a>
        </div>
      ))}
    </div>
  );
};

export default Juegos;

const rrss = [
  {
    href: "https://www.instagram.com/pitercios_ss/",
    img: "/imagenes/RRSS/instagrams.svg"
  },
  {
    href: "https://www.youtube.com/channel/UCfY--oU6p_PpuF1Pao5rDsw",
    img: "/imagenes/RRSS/youtubess.svg"
  },
  {
    href: "https://wa.me/34609761038",
    img: "/imagenes/RRSS/whatsapps.svg"
  },
  {
    href: "mailto:info@pitercios.es",
    img: "/imagenes/RRSS/mail.svg"
  }
];

export default rrss;

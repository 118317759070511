import "../index.css";
import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";

const Atras = ({ setRoute }) => {
  return (
    <div style={{width:'90%'}} onClick={() => setRoute("home")}>
        <spam style={{float:'left',color:'white',cursor:'pointer'}}>
            <IoIosArrowRoundBack size={50} />
        </spam>
    </div>
  );
};

export default Atras;

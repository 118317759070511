/*
import React from "react";
import SECTIONS from "../utils/bach1list";
import Scroll from "./Scroll.js";

import "../index.css";

export default function Apuntes() {
  return (
    <div className="apuntes">
      <div>
        <Scroll secciones={SECTIONS} />
      </div>
    </div>
  );
}
*/

import React, { useState } from "react";
import SECTIONS from "../utils/bach1list";
import Scroll from "./Scroll.js";
import Apuntes from "../components/Apuntes";
import Calculadoras from "../components/Calculadoras";
import Juegos from "../components/Juegos";
import Bach1 from "../components/Bach1";

import "../index.css";

export default function App() {
  const [route, setRoute] = useState("bach1");
  return (
    <>
    <div className="apuntes">
      <div>
        <Scroll secciones={SECTIONS} />

        {route === "apuntes" && <Apuntes setRoute={setRoute} />}
        {route === "calculadoras" && <Calculadoras setRoute={setRoute} />}
        {route === "juegos" && <Juegos setRoute={setRoute} />}
        {route === "bach1" && <Bach1 setRoute={setRoute} />}
        
      </div>
    </div>
    </>
  );
}

const calculadoraslist = [
    {
        texto: "Derivadas",
        img: "/imagenes/calculadoras/derivada2.png",
        link: "https://www.calculatored.com/lang/es/derivative-calculator",
        click: "calculadoras"
    },  
    {
      texto: "Integrales",
      img: "/imagenes/calculadoras/integral.png",
      link: "https://www.calculatored.com/lang/es/integral-calculator",
      click: "calculadoras"
    },
    {
      texto: "Matrices",
      img: "/imagenes/calculadoras/matriz.png",
      link: "https://matrixcalc.org/es/",
      click: "calculadoras"
    },  
  ];
  
  export default calculadoraslist;
  